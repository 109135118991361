<template>
  <div v-if="isEventDataReady">
    <edit-event
      v-bind="{eventData,isDatePassed}"
    />
    <div ref="element">
      <sessions-table
        :sessions-list="sessionsList"
        :selected-session-to-edit.sync="selectedSessionToEdit"
        :is-add-session-form-visible.sync="isAddSessionFormVisible"
        :is-edit-session-form-visible.sync="isEditSessionFormVisible"
        :is-date-passed="isDatePassed"
        @updateSessionsList="getEventDataWithSessions($route.params.id)"
      />
    </div>
    <add-session
      v-if="isAddSessionFormVisible && !isEditSessionFormVisible && !isDatePassed"
      :sessions-list.sync="sessionsList"
      :selected-event-id="parseInt($router.currentRoute.params.id,10)"
      :selected-event-dates="{start_time:eventData.start_time,end_time:eventData.end_time}"
      @addSession="onAddSession()"
    />
    <edit-session
      v-if="isEditSessionFormVisible && !isAddSessionFormVisible && !isDatePassed"
      :entity-id="eventData.entity_id"
      :selected-session-to-edit="selectedSessionToEdit"
      :selected-event-dates="{start_time:eventData.start_time,end_time:eventData.end_time}"
      @editSession="onEditSession()"
    />
  </div>
</template>

<script>
import EditEvent from '@/common/components/PrayerBookings/Edit/EditEvent.vue'
import SessionsTable from '@/common/components/PrayerBookings/Edit/SessionsTable.vue'
import AddSession from '@/common/components/PrayerBookings/common/AddSession.vue'
import EditSession from '@/common/components/PrayerBookings/Edit/EditSession.vue'
import getEventWithSessions from '@/common/compositions/PrayerBookings/getEventWithSessions'
import scroll from '@/common/compositions/common/scrollToElement'

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'EditPrayerBooking',
  components: {
    EditEvent,
    SessionsTable,
    AddSession,
    EditSession,
  },
  data() {
    return {
      isAddSessionFormVisible: false,
      isEditSessionFormVisible: false,
      selectedSessionToEdit: null,
    }
  },
  created() {
    this.getEventDataWithSessions(this.$router.currentRoute.params.id)
  },
  setup() {
    const { scrollToElement, element } = scroll()

    const {
      eventData,
      sessionsList,
      isEventDataReady,
      isDatePassed,
      getEventDataWithSessions,
    } = getEventWithSessions()

    return {
      eventData,
      sessionsList,
      isEventDataReady,
      getEventDataWithSessions,
      scrollToElement,
      element,
      isDatePassed,
    }
  },
  methods: {
    onAddSession() {
      this.getEventDataWithSessions(this.$route.params.id)
      this.scrollToElement()
      this.hideAddSession()
    },
    hideAddSession() {
      this.isAddSessionFormVisible = false
    },
    onEditSession() {
      this.getEventDataWithSessions(this.$route.params.id)
      this.scrollToElement()
      this.hideEditSession()
    },
    hideEditSession() {
      this.isEditSessionFormVisible = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
