<template>
  <b-card>
    <validation-observer
      v-slot="{ handleSubmit }"
    >
      <b-form
        class="mt-2"
        @submit.prevent="handleSubmit(editEvent)"
      >
        <fieldset :disabled="isDatePassed">
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Name"
                rules="required"
              >
                <b-form-group
                  label="Name"
                  label-for="event-name"
                  class="mb-2"
                >
                  <b-form-input
                    id="event-name"
                    v-model="eventData.activity_name"
                    placeholder="Jomaa, Taraweeh, Eid Adha, Eid Fitr, etc"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Presence"
                rules="required"
              >
                <b-form-group
                  label="Presence"
                  label-for="presence_type"
                >
                  <b-form-select
                    id="presence_type"
                    v-model="eventData.presence_type"
                    :options="presenceTypeOptions"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-row>
                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="Start Date"
                    rules="required"
                  >
                    <b-form-group
                      label="Start Date"
                      label-for="start_date"
                      class="mb-2"
                    >
                      <flat-pickr
                        v-model="eventData.start_time"
                        class="form-control"
                        :config="{ enableTime:true,dateFormat: 'Y-m-d H:i',minDate:eventData.start_time}"
                        @input="validationContext.validate(eventData.start_time)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="End Date"
                    rules="required"
                  >
                    <b-form-group
                      label="End Date"
                      label-for="end_date"
                      class="mb-2"
                    >
                      <flat-pickr
                        v-model="eventData.end_time"
                        class="form-control"
                        :config="{ enableTime:true,dateFormat: 'Y-m-d H:i',minDate:eventData.start_time}"
                        @input="validationContext.validate(eventData.end_time)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="validationContext"
                name="Presence Type"
                rules="required"
              >
                <b-form-group
                  label="Presence Type"
                  label-for="presence-type"
                >
                  <b-form-select
                    id="presence-type"
                    v-model="eventData.presence_type"
                    :options="presenceTypeOptions"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="2"
            >
              <validation-provider
                #default="validationContext"
                name="Activate Time"
                rules="required"
              >
                <b-form-group
                  label="Activate Booking Before Hours"
                  label-for="activate_time"
                  class="mb-2"
                >
                  <b-form-input
                    id="activate_time"
                    v-model="eventData.activate_time"
                    type="number"
                    :state="getValidationState(validationContext)"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="General Notes"
                rules="required"
              >
                <b-form-group
                  label="General Notes"
                  label-for="notes"
                  class="mb-2"
                >
                  <b-form-textarea
                    id="notes"
                    v-model="eventData.activity_description"
                    placeholder="General notes for this event"
                    rows="3"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </fieldset>
        <b-button
          v-if="!isDatePassed"
          variant="primary"
          class="mr-1"
          type="submit"
        >
          Update Event
        </b-button>
        <back />
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>

import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'EditEvent',
  components: {
    flatPickr,
    Back,
  },
  props: {
    eventData: { type: Object, default: null },
    isDatePassed: { type: Boolean, default: false },
  },
  data() {
    return {
      presenceTypeOptions: [
        { text: 'In personal', value: 'offline' },
        { text: 'Online', value: 'online' },
        { text: 'Both', value: 'both' },
      ],
    }
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    const { successfulOperationAlert, showErrors } = handleAlerts()
    return {
      successfulOperationAlert,
      showErrors,
      getValidationState,

    }
  },
  methods: {
    editEvent() {
      this.$activities.post(`internalops/prayer-activities/${this.$router.currentRoute.params.id}?_method=PUT`, this.eventData)
        .then(() => {
          this.successfulOperationAlert('Event is updated successfully')
        }).catch(err => {
          this.showErrors(err.response.data.errors)
        })
    },
  },
}
</script>
<style lang="scss">

</style>
